<template>
    <div>
        <component :is="kycObj" :id="route.params.kycId" :user="route.params.userId" />
    </div>
</template>

<script>
import { ref, inject, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import ImgBOKyc from '@/components/ImgBOKyc.vue'

export default {
    name: 'BOKYC',
    components: { ImgBOKyc },
    setup () {
        const axios = inject('axios')
        const route = useRoute()

        const kycObj = computed(() => {
            return ImgBOKyc
        })

        return { 
            route, ImgBOKyc, kycObj
        }
    }
}
</script>

<style>

</style>